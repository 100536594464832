import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCurretJobs } from '../actions/jobAction';
function HomeScreen() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const jobObject = useSelector(state => state.jobs && state.jobs);
  const jobs = jobObject['data'] ? jobObject['data'] : [];
  useEffect(() => {
    dispatch(fetchCurretJobs());
  }, []);

  return (
    <div className="pt-5">
      <div className="row">
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">SI No.</th>
            <th scope="col">Work Name</th>
            <th scope="col">Vendor Name</th>
            <th scope="col">Service / Product Name</th>
            <th scope="col">Comparetive Statement</th>
            <th scope="col">Purchase Order</th>
            <th scope="col">Agreement</th>
            <th scope="col">WO Status</th>
            <th scope="col">Total Amount</th>
            <th scope="col">Advannce Payment</th>
            <th scope="col">Remaining</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            jobs.map(job => {
              return <tr key={job['id']}>

                <td>{job['id']}</td>
                <td>{job['job_data']['work_name']}</td>
                <td>{job['job_data']['vendor_name']}</td>
                <td>{job['job_data']['service_name']}</td>
                <td>
                  {
                    job['job_data']['cs'] && <a href={job['job_data']['cs']} target={`_blank`}>CS</a>
                  }
                </td>
                <td>
                  {
                    job['job_data']['po'] && <a href={job['job_data']['po']} target={`_blank`}>PO</a>
                  }
                </td>
                <td>
                  {
                    job['job_data']['aggrement'] && <a href={job['job_data']['aggrement']} target={`_blank`}>Agreement</a>
                  }
                </td>
                <td>{job['job_data']['wo']}</td>
                <td>{job['job_data']['total_payment']}</td>
                <td>{job['job_data']['advance_payment']}</td>
                <td>{parseFloat(job['job_data']['total_payment']) - parseFloat(job['job_data']['advance_payment'])}</td>
                <td>
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    history(`/doc/edit/${job['id']}`);
                  }}>Edit</a>
                </td>
              </tr>
            })
          }


        </tbody>
      </table>
    </div>
  );
}

export default HomeScreen;


