import logo from "../images/logo.png";

function NoMatch() {


    return (
        <div className="container">
            <div className="card shadow-lg o-hidden border-0 my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-5 col-xxl-6 d-none d-lg-flex">
                            <img
                                src={logo}
                                alt="Logo"
                                style={{
                                    margin: 60,
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="p-5">
                                <div className="text-center">
                                    <h4 className="text-dark mb-4">404</h4>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoMatch;
