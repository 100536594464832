import { UPDATE_JOB_KEY_VALUE, EDIT_JOB_DETAIL, RESET_JOB, FETCH_SINGLE_JOB } from '../../actions/jobAction';
import { LOGOUT } from '../../actions';
import { DISPATCH_UPLOAD } from '../../actions/profileAction';
const initialState = {

};

export function jobReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case `${FETCH_SINGLE_JOB}_SUCCESS`: {

            console.log(payload['data']['data']['job_data']);
            return {
                ...state, job: payload['data']['data']['job_data']
            };

        }

        case UPDATE_JOB_KEY_VALUE: {

            // console.log(payload);
            return {
                ...state, [payload['root_key']]: { ...state[payload['root_key']], [payload['key']]: payload['value'] }
            };

        }

        case EDIT_JOB_DETAIL: {


            return {
                ...state, job: { ...state['job'], ...payload['job'] }
            };

        }


        case RESET_JOB:
            return initialState;

        case LOGOUT:
            return initialState;
        case DISPATCH_UPLOAD: {

            return {
                ...state, [payload['root_key']]: { ...state[payload['root_key']], [payload['sub_key']]: payload['url'] }
            };

        }

        default:
            return state;
    }
}
