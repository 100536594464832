import { useState } from 'react';
import logo from '../images/logo.png';
import { useNavigate } from "react-router-dom";
import { borderRadius } from '@mui/system';
export const NavBar = ({ token, triggerLogout, auth, resetJobAction }) => {
    // console.log(token);
    const history = useNavigate();
    const [open, setOpen] = useState(false);

    return (

        <nav className="navbar navbar-expand-lg navbar-light bg-light">

            <div className="container-fluid">

                <button
                    className="navbar-toggler"
                    type="button"
                    data-mdb-toggle="collapse"
                    data-mdb-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fas fa-bars"></i>
                </button>


                <div className="collapse navbar-collapse">

                    <a onClick={(e) => {
                        e.preventDefault();
                        history('');
                    }} className="navbar-brand mt-2 mt-lg-0" href="#">
                        <img
                            src={logo}
                            width="50"
                            alt="ABG"
                            loading="lazy"
                        />
                    </a>



                </div>

                <div className="d-flex align-items-center">




                    {
                        token === undefined && <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a onClick={e => {
                                    e.preventDefault();
                                    history('login');
                                }} className="nav-link" href="#">Login</a>
                            </li>
                            {/* <li className="nav-item">
                                <a onClick={e => {
                                    e.preventDefault();
                                    history('register');
                                }} className="nav-link" href="#">Register</a>
                            </li> */}

                        </ul>
                    }

                    {
                        token !== undefined && <div className="dropdown">


                            <a
                                className={`dropdown-toggle d-flex align-items-center hidden-arrow ${open ? 'show' : ''}`}
                                href="#"
                                // id="navbarDropdownMenuAvatar"
                                role="button"
                                data-mdb-toggle="dropdown"
                                aria-expanded="false"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpen(!open);
                                }}
                            >
                                <img style={{ width: '50px', borderRadius: '50px' }} alt={auth['name']} src={`${auth['thumb']}`} />
                            </a>

                            <ul
                                style={{ position: 'absolute', right: 0 }}
                                className={`dropdown-menu dropdown-menu-end ${open ? 'show' : ''}`}
                                aria-labelledby="navbarDropdownMenuAvatar"
                            >

                                {
                                    auth['type'] === 1 && <li>
                                        <a className="dropdown-item" href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setOpen(!open);
                                            resetJobAction();
                                            history('/doc/create');
                                        }}>Create Document</a>
                                    </li>
                                }

                                <li>
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        setOpen(!open);
                                        resetJobAction();
                                        history('/profile');
                                    }} className="dropdown-item" href="#">Profile</a>
                                </li>
                                <li>
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        triggerLogout();
                                        history('login');
                                    }} className="dropdown-item" href="#">Logout</a>
                                </li>
                            </ul>
                        </div>
                    }
                </div>

            </div>

        </nav >

    );
};