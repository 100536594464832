import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateJobRootKeyValue, storeJob, fetchCurretJobs, resetJob, fetchJob } from '../actions/jobAction';
import { SubmitButton } from '../components/ActionButton/ButtonAll';
import { useNavigate, useParams } from "react-router-dom";
import { prepareErrorMessage, prepareWidget } from '../util';
import { job_conf } from '../config/jobconfig';


import {



    dispatchUpload,
    uploadJobFiles, uploadJobFilesCustom
} from '../actions/profileAction';


function DocCreate() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const job = useSelector(state => state?.job?.job && state.job.job);
    const { job_id } = useParams();

    console.log(job);
    useEffect(() => {
        (async () => {

            try {
                if (job_id)
                    await dispatch(fetchJob(job_id));
            } catch (e) { }


        })();
    }, [job_id]);

    const processFileUpload = async (array, dispatch_trigger = true) => {
        const formData = new FormData();
        formData.append(`root_key`, array['root_key']);
        formData.append(`key_index`, array['key_index']);
        formData.append(`sub_key`, array['sub_key']);
        formData.append(`file`, array['file']);
        try {
            const response = await dispatch(uploadJobFilesCustom(formData));
            if (dispatch_trigger) {
                await dispatch(dispatchUpload(
                    response['payload']['data']['data']['root_key'],
                    response['payload']['data']['data']['sub_key'],
                    response['payload']['data']['data']['url']
                ));
            }
            return response['payload']['data']['data'];
        }
        catch (e) { }

    }



    const handleSave = async () => {
        let custom_error = prepareErrorMessage(job_conf['create_job'], job);
        setError(custom_error);
        if (Object.keys(custom_error).length === 0) {
            try {
                setLoading(true);
                if (job_id) {
                    await dispatch(storeJob({ job_data: { ...job, id: job_id } }));
                } else {
                    await dispatch(storeJob({ job_data: job }));
                }

                await dispatch(fetchCurretJobs());
                await dispatch(resetJob());
                setLoading(false);
                history("/");
            } catch (e) {
                setLoading(false);
            }
        }
    }


    const handleUpdateKeyValue = (root_key, key, value) => {

        dispatch(updateJobRootKeyValue(root_key, key, value));
    }
    return (
        <div className="container">
            <div style={{ width: '70%', margin: 'auto' }}>
                <Form>
                    {
                        prepareWidget(job_conf['create_job'], handleUpdateKeyValue, job ? job : {}, processFileUpload, error)
                    }
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <SubmitButton handleSave={handleSave} loading={loading} />
                    </div>
                </Form>
            </div>


        </div>
    );
}

export default DocCreate;