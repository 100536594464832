import Form from 'react-bootstrap/Form';
import { memo } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEffect, useState, useCallback } from 'react';
const TextAreaCustom = ({ label, handleUpdateKeyValue, value, root_key, sub_key, type = `text`, invalid_message = '', invalid = false }) => {
    const [data, setData] = useState('');
    useEffect(() => {
        setData(value);
    }, [value]);
    return (
        // <Form.Group className="mb-3" controlId="formBasicEmail">
        //     <Form.Label>{label}</Form.Label>
        //     <Form.Control onChange={(e) => {

        //         handleUpdateKeyValue(root_key, sub_key, e.target.value);
        //     }} value={value} as="textarea" rows={3} />

        // </Form.Group>

        <>
            <Form.Label>{label}</Form.Label>
            <CKEditor
                rows={3}
                editor={ClassicEditor}
                data={data}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log('My call', root_key, sub_key, data);
                    setData(data);
                }}
                onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                    handleUpdateKeyValue(root_key, sub_key, data);
                }}
                onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                    handleUpdateKeyValue(root_key, sub_key, data);
                }}

            />
            {
                invalid_message !== '' && <div style={{
                    width: '100%',
                    marginTop: '0.25rem',
                    fontSize: "0.875em",
                    color: "#dc3545"
                }}>
                    {invalid_message}
                </div>
            }

        </>
    );
}

export default memo(TextAreaCustom);



