import Form from 'react-bootstrap/Form';
import { memo, useRef, useEffect, useState } from "react";
import $ from 'jquery';
// import logo from '../../images/logo.png';
const ProfileImage = ({ setThumb, auth }) => {
    const input_image = useRef();
    const [url, setUrl] = useState(null);
    const readURL = (input) => {

        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = async function (e) {
                // console.log(e.target.result)
                // updateKeyValue('thumb', e.target.result, user['id']);
                // updateKeyValue('new_image', true, user['id']);
                setUrl(e.target.result);
                setThumb(e.target.result);
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    useEffect(() => {
        $(input_image.current).change(function (e) {
            readURL(this);
        });
    }, []);
    console.log(auth);
    return (
        <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
            <input style={{ display: 'none' }} ref={input_image} type="file" accept="image/*" />
            <div className="text-center">
                <img style={{ height: '300px' }} src={url ? url : auth['thumb'] ? auth['thumb'] : "https://t3.ftcdn.net/jpg/05/16/27/58/240_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"} className="rounded" alt="..." />
            </div>

            <button style={{ marginTop: '10px', marginLeft: 'auto', marginRight: 'auto' }} onClick={() => {
                // setEnableedit(true);
                $(input_image.current).trigger('click');
            }} type="button" className="btn btn-sm  btn-success">Upload</button>
        </div>


    );
}

export default memo(ProfileImage);



