import Form from 'react-bootstrap/Form';
import { memo } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const PlainTextArea = ({ label, handleUpdateKeyValue, value, root_key, sub_key, type = `text`, invalid_message = '', invalid = false }) => {
    return (
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{label}</Form.Label>
            <Form.Control onChange={(e) => {

                handleUpdateKeyValue(root_key, sub_key, e.target.value);
            }} value={value} as="textarea" rows={3} />

        </Form.Group>


    );
}

export default memo(PlainTextArea);



