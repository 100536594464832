import { combineReducers } from 'redux';
import { jobReducer } from './jobReducer';
import { jobsReducer } from './jobsReducer';
import { loginReducer } from './loginReducer';
import { profileReduceer } from './profileReduceer';
import { jobObjectReducer } from './jobObjectReducer';
import { filterReducer } from './filterReducer';


export default combineReducers({
    auth: loginReducer,
    profile: profileReduceer,
    job: jobReducer,
    jobs: jobsReducer,
    jobObject: jobObjectReducer,
    filterObject: filterReducer
});
