import { fetchJob, makeShortList, fetchIndexes, fetchIndexesProfile } from "../actions/jobAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FilterArea from "../components/FilterPanel/FilterArea";

import ProfileSection from "../components/Profile/ProfileSection";
import CandidateList from "../components/Condidate/CandidateList";
import ProfileWrapper from "../components/Profile/ProfileWrapper";
import {

    putToken
} from '../actions/';
function ShortListScreen() {
    const [index, setIndex] = useState(0);
    const [profile_index, setProfileIndex] = useState(0);
    const { sub_token, job_id } = useParams();
    const jobObject = useSelector(state => state.jobObject[job_id] && state.jobObject[job_id]);
    const filterObject = useSelector(state => state.filterObject && state.filterObject);
    const dispatch = useDispatch();
    const candidates = jobObject && jobObject['candidates'] ? jobObject['candidates'] : [];
    const [sort, setSort] = useState(0);

    const [filter, setFilter] = useState(
        {
            filter1: '',
            filter2: '',
            filter3: '',
        });


    const [type, setType] = useState(
        {
            type1: '',
            type2: '',
            type3: '',
        });




    const selected_candidate = candidates.filter(candidate => {
        return candidate['pivot']['status'] === 1
    });
    const retriveAndSetProfileIndex = (id) => {
        let index = candidates.findIndex(can => {
            return can['id'] === id;
        });
        setProfileIndex(index);

    }
    const makeApplyShortList = async (job_id, candidate_id, status) => {
        await dispatch(makeShortList(job_id, candidate_id, status));
    }

    const filterFunction = (x) => {

        return true;
    }

    const sortFunction = (x, y) => {

        if (sort === 0)
            return x['id'] - y['id'];
        else if (sort === 1) return y['id'] - x['id'];
        else if (sort === 3) return x.name.localeCompare(y.name);
        else return y.name.localeCompare(x.name);
    }


    const searchFilter = async (type, title, job_id) => {
        await dispatch(fetchIndexesProfile(type, title, job_id));
    }





    useEffect(() => {
        (async () => {

            try {
                await dispatch(putToken(sub_token));
                await dispatch(fetchJob(job_id));
                await dispatch(fetchIndexes(1));
                await dispatch(fetchIndexes(2));
                await dispatch(fetchIndexes(3));

            } catch (e) { }


        })();
    }, []);

    return (
        <div className="container">
            {
                jobObject &&
                <>
                    <div className="job_heading row">
                        <h3>
                            {jobObject['title']}
                        </h3>
                    </div>
                    <FilterArea job_id={jobObject['id']} searchFilter={searchFilter} sort={sort} setSort={setSort} filterObject={filterObject} />
                    <div className="row condidate_container">
                        <div className="col-sm-3">
                            <ul className="candidate_select">
                                <li className={`${index === 0 ? 'active' : ''}`}>
                                    <a onClick={e => {
                                        e.preventDefault();
                                        setIndex(0);
                                    }} href="#">All Candidate</a>
                                </li>
                                <li className={`${index === 1 ? 'active' : ''}`}>
                                    <a onClick={e => {
                                        e.preventDefault();
                                        setIndex(1);
                                    }} href="#">Shortlisted</a>
                                </li>
                            </ul>
                            <div className="condidate_list">
                                <CandidateList job={jobObject} makeApplyShortList={makeApplyShortList} retriveAndSetProfileIndex={retriveAndSetProfileIndex} status={index} candidates={jobObject['candidates'].sort(sortFunction).filter(filterFunction)} />
                            </div>


                        </div>
                        <div className="col-sm-9 profile_area">
                            {
                                candidates[profile_index] && candidates[profile_index] && <ProfileWrapper profile={candidates[profile_index]} />
                            }
                        </div>
                    </div>
                    {
                        selected_candidate.length > 0 && <div className="select_action">
                            <div className="container validate_action">
                                {
                                    selected_candidate.length > 0 && <p>Selected Candidates ({selected_candidate.length})</p>
                                }

                                <a href="#">
                                    Validate the selection and continue
                                </a>
                            </div>
                        </div>
                    }

                </>
            }

        </div >
    );
}

export default ShortListScreen;
