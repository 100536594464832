import FileInputCustom from "../components/InputElement/FileInputCustom";
import PlainTextArea from "../components/InputElement/PlainTextArea";
import SelectInputCustom from "../components/InputElement/SelectInputCustom";
import TextAreaCustom from "../components/InputElement/TextAreaCustom";
import TextInputCustom from "../components/InputElement/TextInputCustom";

export const prepareErrorMessage = (widget_array, data = {}) => {
    // console.log(data);
    let error = {};
    for (let i in widget_array) {
        for (let j in widget_array[i]['wrappers']) {
            for (let z in widget_array[i]['wrappers'][j]['widgets']) {
                if (widget_array[i]['wrappers'][j]['widgets'][z]['validation']) {
                    for (let p in widget_array[i]['wrappers'][j]['widgets'][z]['validation']['rule']) {
                        if (widget_array[i]['wrappers'][j]['widgets'][z]['validation']['rule'][p] === 'required') {
                            if (

                                !(
                                    data[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']] &&
                                    data[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']] !== ''
                                )
                            ) {
                                error[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']] = error[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']] ? error[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']].push(widget_array[i]['wrappers'][j]['widgets'][z]['validation']['message'][p]) : [widget_array[i]['wrappers'][j]['widgets'][z]['validation']['message'][p]];
                            }
                        } else if (widget_array[i]['wrappers'][j]['widgets'][z]['validation']['rule'][p] === 'email') {
                            if (

                                !(
                                    data[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']] &&
                                    data[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']] !== '' &&
                                    validateEmail(data[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']])
                                )
                            ) {
                                error[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']] = error[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']] ? [...error[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']], widget_array[i]['wrappers'][j]['widgets'][z]['validation']['message'][p]] : [widget_array[i]['wrappers'][j]['widgets'][z]['validation']['message'][p]];
                            }
                        }
                    }

                }


            }
        }
    }

    return error;
}


export const prepareWidget = (widget_array, handleUpdateKeyValue, profileData, processFileUpload, errors = {}) => {
    let invalid_message = '';
    let invalid = false;
    return widget_array.map((section, section_key) => {
        return <div key={section_key} className="row" style={{ backgroundColor: '#F8F9FA', marginTop: '20px', padding: '20px' }}>
            <h2>
                {
                    section['title']
                }
            </h2>
            {
                // invalid_message = '', invalid = false
                section['wrappers'].map((wrapper, wrapper_key) => {
                    return <div key={wrapper_key} className={wrapper['wrapper']}>
                        {
                            wrapper['widgets'].map((array, s_key) => {
                                invalid = errors[array['sub_key']] ? true : false;
                                invalid_message = invalid ? errors[array['sub_key']][0] : '';

                                if (array['type'] === 'select') {
                                    return <SelectInputCustom invalid={invalid} invalid_message={invalid_message} key={s_key} handleUpdateKeyValue={handleUpdateKeyValue} label={array['label']} value={profileData[array['sub_key']] && profileData[array['sub_key']]} root_key={array['root_key']} sub_key={array['sub_key']} data={array['data']} />
                                }
                                else if (array['type'] === 'textarea') {
                                    return <TextAreaCustom invalid={invalid} invalid_message={invalid_message} key={s_key} handleUpdateKeyValue={handleUpdateKeyValue} label={array['label']} value={profileData[array['sub_key']] ? profileData[array['sub_key']] : ''} root_key={array['root_key']} sub_key={array['sub_key']} />
                                }
                                else if (array['type'] === 'textarea_plain') {
                                    return <PlainTextArea invalid={invalid} invalid_message={invalid_message} key={s_key} handleUpdateKeyValue={handleUpdateKeyValue} label={array['label']} value={profileData[array['sub_key']] ? profileData[array['sub_key']] : ''} root_key={array['root_key']} sub_key={array['sub_key']} />
                                }
                                else if (array['type'] === 'file') {
                                    return <FileInputCustom key_index={s_key} processFileUpload={processFileUpload} invalid={invalid} invalid_message={invalid_message} key={s_key} handleUpdateKeyValue={handleUpdateKeyValue} label={array['label']} value={profileData[array['sub_key']] ? profileData[array['sub_key']] : ''} root_key={array['root_key']} sub_key={array['sub_key']} />
                                }
                                else {
                                    return <TextInputCustom invalid={invalid} invalid_message={invalid_message} type={array['type']} key={s_key} handleUpdateKeyValue={handleUpdateKeyValue} label={array['label']} value={profileData[array['sub_key']] && profileData[array['sub_key']]} root_key={array['root_key']} sub_key={array['sub_key']} />
                                }
                            })
                        }
                    </div>
                })
            }
        </div>
    });



}


const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


export const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
}

export const parseProgressText = (text) => {
    const part = text.split("_");
    // console.log(part[0]);
    if (part[1])
        return capitalize(part[0]) + " " + part[1];
    else return capitalize(part[0]);
}


export const triggerPopUpClose = () => {
    var message = 'Send the message to parent ';
    window.parent.postMessage(message, '*');
    // / alert('dd');
};



export const getImageWidgets = (widget_array, data = {}) => {
    let error = {};
    let uploads = [];

    for (let i in widget_array) {
        for (let j in widget_array[i]['wrappers']) {
            for (let z in widget_array[i]['wrappers'][j]['widgets']) {
                // console.log(widget_array[i]['wrappers'][j]['widgets'][z]);
                if (widget_array[i]['wrappers'][j]['widgets'][z]['type'] === 'file') {
                    // console.log('Hola', data[uploads[widget_array[i]['wrappers'][j]['widgets'][z]['root_key']]][uploads[widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']]]);
                    // console.log('sssss', widget_array[i]['wrappers'][j]['widgets'][z]);
                    // console.log();
                    uploads.push({
                        root_key: widget_array[i]['wrappers'][j]['widgets'][z]['root_key'],
                        sub_key: widget_array[i]['wrappers'][j]['widgets'][z]['sub_key'],
                        file: data[widget_array[i]['wrappers'][j]['widgets'][z]['root_key']][widget_array[i]['wrappers'][j]['widgets'][z]['sub_key']]
                    });
                }
                // widget_array[i]['wrappers'][j]['widgets'][z]
            }
        }
    }

    return uploads;
}


export const isValidUrl = urlString => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
}