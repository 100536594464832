import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { fetchProfile, loginSubmit } from "../actions";
import { fetchJobProfile } from "../actions/profileAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../images/logo.png";

function LoginScreen() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [authData, setAuthData] = useState({
    username: "",
    password: "",
  });
  const handleLogin = async () => {
    setLoading(true);
    try {
      await dispatch(loginSubmit(authData["username"], authData["password"]));
      const response = await dispatch(fetchProfile());
      setLoading(false);
      history("/");
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <div class="bg-gradient-primary">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-12 col-xl-10">
              <div class="card shadow-lg o-hidden border-0 my-5">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-lg-6 d-none d-lg-flex">
                      <img
                        src={logo}
                        alt="Logo"
                        style={{
                          margin: 60,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div class="col-lg-6">
                      <div class="p-5">
                        <div class="text-center">
                          <h4 class="text-dark mb-4">Welcome Back!</h4>
                        </div>

                        <Form
                          onSubmit={async (e) => {
                            e.preventDefault();
                            await handleLogin();
                          }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              onKeyUp={(e) => {
                                setAuthData({
                                  ...authData,
                                  username: e.target.value,
                                });
                              }}
                              type="email"
                              placeholder="Enter Your Phone Number"
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              onKeyUp={(e) => {
                                setAuthData({
                                  ...authData,
                                  password: e.target.value,
                                });
                              }}
                              type="password"
                              placeholder="Password"
                            />
                          </Form.Group>

                          <button
                            className="w-100 btn btn-lg btn-primary"
                            type="submit"
                          >
                            {loading ? (
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              "Sign in"
                            )}
                          </button>
                        </Form>

                        <br />

                        <div class="text-center">
                          <a class="small" href="register" onClick={(e) => {
                            e.preventDefault();
                            history('/register');
                          }}>
                            Create an Account!
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
