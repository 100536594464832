import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { fetchProfile, updateUser, UpdateUserProfileImage } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { prepareWidget } from "../util";
import { signup_config_variable } from "../config/config";

import logo from "../images/logo.png";
import ProfileImage from "../components/ImagesOpeeration/ProfileImage";

function EditProfileScreen() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [authData, setAuthData] = useState({});
    const [errors, setErrors] = useState({});
    const auth = useSelector(state => state.auth && state.auth);
    const [thumb, setThumb] = useState(null);
    const handleLogin = async () => {
        setLoading(true);
        try {
            await dispatch(updateUser(authData));
            if (thumb) {
                await dispatch(UpdateUserProfileImage({
                    image: thumb
                }));
                await dispatch(fetchProfile());
                setThumb(null);
            }
            const response = await dispatch(fetchProfile());
            setLoading(false);
            setErrors({});
            history("/");
        } catch (e) {
            const errorData = e?.error?.response?.data?.errors;
            if (errorData) setErrors(errorData);
        }
        setLoading(false);
    };

    const handleUpdateKeyValue = (root_key, key, value) => {
        setAuthData({ ...authData, [key]: value });
    };

    useEffect(() => {
        setAuthData({ ...authData, ...auth });
    }, [auth]);
    // console.log(auth);
    return (
        <div className="container">
            <div class="card shadow-lg o-hidden border-0 my-5">
                <div class="card-body p-0">
                    <div class="row">
                        <div class="col-lg-5 col-xxl-6 d-none d-lg-flex">
                            <ProfileImage auth={auth} setThumb={setThumb} />
                        </div>
                        <div class="col-lg-7 col-xxl-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h4 class="text-dark mb-4">Update Profile</h4>
                                </div>
                                <Form
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        await handleLogin();
                                    }}
                                >
                                    {prepareWidget(
                                        signup_config_variable["signup_config"],
                                        handleUpdateKeyValue,
                                        authData,
                                        null,
                                        errors
                                    )}

                                    <button
                                        className="w-100 btn btn-lg btn-primary"
                                        type="submit"
                                    >
                                        {loading ? (
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : (
                                            "Update"
                                        )}
                                    </button>
                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditProfileScreen;
