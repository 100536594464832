import React, { useState } from 'react';
import logo from './logo.svg';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

import Button from 'react-bootstrap/Button';
import { NavBar } from './components/NavBar';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'
import HomeScreen from './Pages/HomeScreen';
import LoginScreen from './Pages/LoginScreen';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, triggerLogout } from './actions';
import { fetchJobProfile } from './actions/profileAction';
import { fetchCurretJobs, resetJob } from './actions/jobAction';
import { useEffect } from 'react';



import CommonScreen from './Pages/CommonScreen';
import NoMatch from './Pages/NoMatch';
import JobsScreen from './Pages/JobsScreen';
import ShortListScreen from './Pages/ShortListScreen';
import DocCreate from './Pages/DocCreate';
import EditProfileScreen from './Pages/EditProfileScreen';
function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const dispatch = useDispatch();
  const token = useSelector(state => state.auth['api_token'] && state.auth['api_token']);
  const auth = useSelector(state => state.auth && state.auth);
  const logOutAction = () => {
    dispatch(triggerLogout());
  }
  const resetJobAction = () => {

    dispatch(resetJob());
  }
  console.log(auth);
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <Router>
        {
          token && <NavBar auth={auth} token={token} triggerLogout={logOutAction} resetJobAction={resetJobAction} />
        }

        <Routes>

          {
            token ? <>
              <Route exact path="/" element={<HomeScreen />} />
              <Route exact path="/doc/create" element={<DocCreate />} />
              <Route exact path="/doc/edit/:job_id" element={<DocCreate />} />
              <Route exact path="/profile" element={<EditProfileScreen />} />
              <Route path="*" element={<NoMatch />} />
            </> : <>
              <Route exact path="/" element={<LoginScreen />} />
              <Route exact path="/login" element={<LoginScreen />} />
            </>
          }






        </Routes>
      </Router>
    </ThemeProvider>

  );
}

export default App;