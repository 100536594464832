



// Design / Creative(119)
// Production / Operation(142)
// Hospitality / Travel / Tourism(117)
// Commercial(69)
// Beauty Care / Health & Fitness(15)
// IT & Telecommunication(466)
// Marketing / Sales(791)
// Customer Service / Call Centre(143)
// Media / Ad./ Event Mgt. (78)
// Medical / Pharma(193)
// Agro(Plant / Animal / Fisheries)(59)
// NGO / Development(186)
// Research / Consultancy(36)
// Secretary / Receptionist(87)
// Data Entry / Operator / BPO(71)
// Driving / Motor Technician(7)
// Security / Support Service(48)
// Law / Legal(25)
// Electrician / Construction / Repair(12)



const job_category = [
    {
        label: 'Accounting/Finance',
        value: 'Accounting/Finance'
    },
    {
        label: 'Bank/ Non-Bank Fin. Institution',
        value: 'Bank/ Non-Bank Fin. Institution'
    },
    {
        label: 'Supply Chain / Procurement',
        value: 'Supply Chain / Procurement'
    },
    {
        label: 'Education / Training',
        value: 'Education / Training'
    },
    {
        label: 'Engineer / Architects',
        value: 'Engineer / Architects'
    },
    {
        label: 'Garments / Textile',
        value: 'Garments / Textile'
    },
    {
        label: 'Supply Chain / Procurement',
        value: 'Supply Chain / Procurement'
    },

    {
        label: 'HR / Org.Development',
        value: 'HR / Org.Development'
    },

    {
        label: 'Gen Mgt / Admin',
        value: 'Gen Mgt / Admin'
    },

    {
        label: 'Design / Creative',
        value: 'Design / Creative'
    },

    {
        label: 'Production / Operation',
        value: 'Production / Operation'
    },

    {
        label: 'Hospitality / Travel / Tourism',
        value: 'Hospitality / Travel / Tourism'
    },
    {
        label: 'IT & Telecommunication',
        value: 'IT & Telecommunication'
    }


];



export const job_conf = {
    create_job: [
        {
            'title': 'Update Document',
            'wrappers': [
                {
                    wrapper: 'col-sm-6',
                    widgets: [

                        {
                            label: 'Work Name',
                            root_key: 'job',
                            sub_key: 'work_name',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Please Unit']
                            }
                        },
                        {
                            label: 'Service/Product Name',
                            root_key: 'job',
                            sub_key: 'service_name',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Please Unit']
                            }
                        }

                    ]
                },
                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: 'Vendor Name',
                            root_key: 'job',
                            sub_key: 'vendor_name',
                            type: 'text',
                            validation: {
                                rule: ['required'],
                                message: ['Please Unit']
                            }
                        },
                        {
                            label: 'Comparative Statement',
                            root_key: "job",
                            sub_key: "cs",
                            type: "file",
                            validation: {
                                rule: ["required"],
                                message: ["Please upload Comparative Statement"],
                            },
                        }


                    ]
                },



                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: 'PO',
                            root_key: "job",
                            sub_key: "po",
                            type: "file",
                            validation: {
                                rule: ["required"],
                                message: ["Please upload Comparative Statement"],
                            },
                        }


                    ]
                },

                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: 'Aggrement',
                            root_key: "job",
                            sub_key: "aggrement",
                            type: "file",
                            validation: {
                                rule: ["required"],
                                message: ["Please upload Comparative Statement"],
                            },
                        }


                    ]
                },



                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: "Total Paymennt",
                            root_key: 'job',
                            sub_key: 'total_payment',
                            type: 'number',
                            validation: {
                                rule: ['required'],
                                message: ['Total Paymennt is required']
                            }
                        },
                        {
                            label: "Work Worder Status",
                            root_key: "job",
                            sub_key: "wo_status",
                            type: "select",
                            data: [
                                {
                                    label: "Delivered",
                                    value: "Advance",
                                },
                                {
                                    label: "Comleted",
                                    value: "Comleted",
                                },

                            ],
                            validation: {
                                rule: ["required"],
                                message: ["This feild is required"],
                            },
                        },


                    ]
                },

                {
                    wrapper: 'col-sm-6',
                    widgets: [
                        {
                            label: "Advance Paymennt",
                            root_key: 'job',
                            sub_key: 'advance_payment',
                            type: 'number',
                            validation: {
                                rule: ['required'],
                                message: ['Total Paymennt is required']
                            }
                        }


                    ]
                },

            ]
        }
    ]








};



